import './ReactionFooter.css'
import { Grid,Button,Typography } from '@mui/material';

export default function ReactionFooter({success,text, buttonHandle, type=''}) {
    let color = 'primary';
    let bgColor = '#ffffff';
    let buttonText = 'Check';
    switch (success) {
        case 1: color = 'primary'; bgColor='#d1ffd1'; break;
        case 0: color = 'primary'; bgColor='#ffffff'; break;
        case -1: color = 'error';  bgColor='#ffe1e1'; break;
    }
    switch (type) {
        case 'test': buttonText = 'Next'; break;
        default:
            switch (success) {
                case 1: color = 'primary'; bgColor='#d1ffd1'; buttonText='Next'; break;
                case 0: color = 'primary'; bgColor='#ffffff'; buttonText='Check'; break;
                case -1: color = 'error';  bgColor='#ffe1e1'; buttonText='Retry'; break;
            }
            break;
    }

    return (
        <div className={'reactionDiv'} style={{ backgroundColor: bgColor}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} align="left">
                    <Typography variant="h4" component="h5">
                        {text}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}  align="right">
                    <Button size="large" variant="contained" color={color} fullWidth onClick={buttonHandle}>{buttonText} </Button>
                </Grid>
            </Grid>
        </div>
    );
}