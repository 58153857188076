import {Box, Button, Container, Grid, Link, Typography} from '@mui/material';
import Page from '../components/Page';
import FolderItem from '../components/custom/FolderItem';
import {useCallback, useEffect, useState} from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MyFolders() {
  const [folders, setState] = useState([])

  const loadData = () => {
    axios.get(config.apiUrl + '/my-folders', {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response) => {
      if (response.data.success) {
        setState(response.data.data);
        api.getUser();
      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          alert(error.response.data.message);
        }
      }
    });
  }
  useEffect(loadData,[]);
  return (
      <Page title="Wordlist | eagles.uz">
        <Container>
          <Box sx={{ pb: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}  padding={1}>
                <Typography variant="h4">Folders</Typography>
              </Grid>
              <Grid item xs={12} md={6}  padding={1}>
                {/*{isActive?<Button*/}
                {/*    to="/dashboard/add-folder"*/}
                {/*    size="small"*/}

                {/*    component={RouterLink}*/}
                {/*    startIcon={<Icon icon={plusFill} />}*/}
                {/*>*/}
                {/*  Add new folder*/}
                {/*</Button>:""}*/}
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            {folders.map(function(item, i){
              return <Grid item xs={12} md={6} lg={4} padding={1} key={i}><FolderItem item={item} /></Grid>
            })}
          </Grid>
        </Container>

      </Page>
  );
}