import { Card, Typography, CardContent, Link, Grid, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import icon1 from '@iconify/icons-ant-design/check-square-outlined';
import icon2 from '@iconify/icons-ant-design/edit-outlined';
import icon3 from '@iconify/icons-ant-design/sound-twotone';
import icon4 from '@iconify/icons-ant-design/file-text';
import icon5 from '@iconify/icons-ant-design/audio';
import icon6 from '@iconify/icons-ant-design/check-circle';
import icon7 from '@iconify/icons-ant-design/paper-clip-outline';
import { Icon } from '@iconify/react';


export default function StudyActionButtons({item, wordslistInfo}) {
  let actions;
  if (item>0) {
    actions = [
      {
        name: "Test",
        icon: icon1,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/test"
      },
      {
        name: "Write",
        icon: icon2,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write"
      },
      {
        name: "Write from audio",
        icon: icon3,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write-with-audio"
      },
      {
        name: "Speech",
        icon: icon5,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/speech"
      },
      {
        name: "True/False",
        icon: icon6,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/true-false"
      },
      {
        name: "Test unstudied",
        icon: icon1,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/test/unstudied"
      },
      {
        name: "Write unstudied",
        icon: icon2,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write/unstudied"
      },
      {
        name: "Example sentences",
        icon: icon7,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/example-sentences"
      },
      {
        name: "Read text",
        icon: icon4,
        enabled: wordslistInfo.read_texts > 0 ? true : false,
        url: "/dashboard/wordlist/" + item + "/read-text"
      }
    ];
  }else if (item.substr(0,10) ==='needrepeat'){
    actions = [
      {
        name: "Test",
        icon: icon1,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/test"
      },
      {
        name: "Write",
        icon: icon2,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write"
      },
      {
        name: "Speech",
        icon: icon5,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/speech"
      }    ];

  }else{
    actions = [
      {
        name: "Test",
        icon: icon1,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/test"
      },
      {
        name: "Write",
        icon: icon2,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write"
      },
      {
        name: "Write from audio",
        icon: icon3,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write-with-audio"
      },
      {
        name: "Speech",
        icon: icon5,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/speech"
      },{
        name: "True/False",
        icon: icon6,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/true-false"
      },{
        name: "Test unstudied",
        icon: icon1,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/test/unstudied"
      },
      {
        name: "Write unstudied",
        icon: icon2,
        enabled: true,
        url: "/dashboard/wordlist/" + item + "/write/unstudied"
      }
    ];
  }
  return (
      <Grid container spacing={3}>
        {actions.map(function(item, i){
          if (item.enabled) {
            return <Grid item xs={12} md={6} lg={4} padding={1} key={i}>
              <Link to={item.url} color="inherit" underline="none" component={RouterLink}>
                <Card sx={{ minWidth: 250 }} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 9px 0px, rgb(145 158 171 / 54%) 0px 5px 2px 0px", border:"1px solid #62626282" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      <Icon icon={item.icon} width={24} height={24} style={{ marginRight: "10px" }}/>
                      {item.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          }
        })}
      </Grid>
  );
}