import { Box, Container, Grid, Typography } from '@mui/material';
import Page from '../components/Page';
import FolderItem from '../components/custom/FolderItem';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

  export default function Folder() {
  const [folders, setState] = useState([])

  useEffect(() => {
    axios.get(config.apiUrl+'/folders', {
        headers: {
          'Authorization': api.getToken()
        }
      }).then((response)=>{
      if (response.data.success){
        setState(response.data.data);
        api.getUser();
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
  },[]);

  return (
    <Page title="Wordlist | eagles.uz">
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Folders</Typography>
        </Box>
        <Grid container spacing={3}>
          {folders.map(function(item, i){
            return <Grid item xs={12} md={6} lg={4} padding={1} key={i}><FolderItem item={item} /></Grid>
            })}
        </Grid>
      </Container>
    </Page>
  );
}