import {Box, Button, Card, CardHeader, Container, Link, Modal, Stack} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import api from '../api';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {Icon} from "@iconify/react";
import listIcon from "@iconify/icons-eva/list-outline";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import calendarIcon from "@iconify/icons-eva/calendar-outline";
import joinIcon from "@iconify/icons-eva/plus-square-outline";
import Swal from 'sweetalert2'


export default function Leagues() {
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [joinLeagueID, setJoinLeagueID] = useState(0);
    const [status, setStatus] = useState('');
    let navigate = useNavigate();
    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflowY:'auto',
        textAlign:'center',
        p: 4,
    };


    useEffect(() => {
        loadData();
    },[page,status]);

    const loadData = () => {
        api.get('/leagues?page='+page+(status != ""?'&status='+encodeURIComponent(status):'')).then(function(response) { return response; })
            .then(function(resultData) {
                if (resultData.success){
                    setData(resultData.data);
                }
            });
    }

    const joinLeague = () => {
        setOpenModal(false);
        api.post('/league/join',{league_id:joinLeagueID})
            .then(function(response) { return response; })
            .then(function(resultData) {
                if (resultData.success){
                    loadData();
                    Swal.fire({
                        title: "Good job!",
                        text: "You joined the league!",
                        icon: "success"
                    });
                }
            });
    }
    const joinLeagueModal = (league_id) => {
        setJoinLeagueID(league_id);
        setOpenModal(true);
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <Page title="Leagues | eagles.uz">
            <Container>
                <Card sx={{mt:3}}>
                    <CardHeader title={ "Leagues "} />
                    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                        <FormControl key={'f1'} sx={{ m: 1, minWidth: 100 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                            <Select
                                id="level-select"
                                value={status}
                                label="Status"
                                onChange={(event)=>{setStatus(event.target.value)}}
                                key={'s1'}
                            >
                                <MenuItem value={''}>-All-</MenuItem>
                                <MenuItem value={"new"}>New</MenuItem>
                                <MenuItem value={"active"}>Active</MenuItem>
                                <MenuItem value={"finished"}>Finished</MenuItem>
                            </Select>
                        </FormControl>
                        {data?.data?.map((row) => (

                            <Card sx={{ display: 'flex',my:1 }} key={row.league_id}>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 250 }}
                                    image={"https://files.eagles.uz/leagues/" + row.image}
                                    alt="legue"
                                    style={{ objectFit:"cover" }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Badge color={ row.status === "new"?"secondary":(row.status === "active"?"primary":"warning") } badgeContent={row.status.toUpperCase()}>
                                            <Typography component="div" variant="h5">
                                                {row.name}
                                            </Typography>
                                        </Badge>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {row.author}
                                        </Typography>
                                        <Typography variant="p" color="text.secondary" component="div">
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} >
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Icon icon={calendarIcon} />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Starting time" secondary={row.begin_date} />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Icon icon={calendarIcon} />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Finishing time" secondary={row.end_date} />
                                                </ListItem>
                                            </List>


                                            <Button
                                                variant="contained"
                                                component={RouterLink}
                                                to={"/dashboard/league/"+row.league_id+"/users"}
                                                startIcon={<Icon icon={listIcon} />}
                                            >Participants
                                            </Button>

                                            {row.user_id != api.getUserId()?
                                                <Button
                                                    sx={{ ml:1 }}
                                                    variant="contained"
                                                    onClick={()=>joinLeagueModal(row.league_id)}
                                                    to={"#"}
                                                    startIcon={<Icon icon={joinIcon} />}
                                                >Join
                                                </Button>
                                            :""}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        ))}
                        <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
                    </Box>
                </Card>


            </Container>
            <Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <h2>Are you sure?</h2>
                    <Button variant="outlined" onClick={()=>setOpenModal(false)}  sx={{ mx:1 }}>No</Button>
                    <Button variant="outlined" onClick={joinLeague} sx={{ mx:1 }}>Yes</Button>
                </Box>
            </Modal>
        </Page>
    );
}