import {Card, Container, CardContent, Grid, Box, Avatar, Typography, Link} from "@mui/material";
import Page from "../components/Page";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../config.json";
import api from "../api";
import {Link as RouterLink} from "react-router-dom";

export default function NeedRepeat(){
    const [folders, setFolders] = useState([]);
    useEffect(() => {
        axios.get(config.apiUrl+'/word-repeat/get-wordlist', {
            headers: {
                'Authorization': api.getToken()
            }
        }).then((response)=>{
            setFolders(response.data.data);
            api.getUser();
        }).catch((error)=>{
            if (error.response != undefined){
                if (error.response.status == 401){
                    api.logOut();
                }else{
                    alert(error.response.data.message);
                }
            }
        });
    },[]);
    return (
        <Page title="Wordlist | eagles.uz">
            <Container>
                <Box sx={{ pb: 2 }}>
                    <Typography variant="h3">The Best Memorization Technique</Typography>
                </Box>
                <Box sx={{ pb: 3 }}>
                    <Typography variant="h4">Please repeat all these words today </Typography>
                </Box>
                {folders.map(function(item, i) {
                    return <Link to={ '/dashboard/wordlist/needrepeat-'+item.period_repeat } color="inherit" underline="none"  component={RouterLink}>
                        <Card  style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", border:"1px solid #62626282" }}  sx={{ mb: 2 }} >
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center', // This aligns items vertically center
                                                height: 60, // You can adjust the height
                                            }}
                                        >
                                            <Typography variant="h3">{item.period_repeat} days repeat words</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Avatar sx={{ bgcolor: "#6aa46a", width: 56, height: 56 }}>{item.cnt}</Avatar>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                })}
            </Container>
        </Page>
    );
}