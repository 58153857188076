import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import foldericon from '@iconify/icons-eva/people-fill';
import repeatfill from '@iconify/icons-eva/repeat-fill';
import longrepeatfill from '@iconify/icons-eva/refresh-fill';
import starfill from '@iconify/icons-eva/star-fill';
import bookfill from '@iconify/icons-eva/book-open-outline';
import ratefill from '@iconify/icons-eva/bar-chart-fill';
import gearfill from '@iconify/icons-eva/settings-2-outline';
import leaguesicon from '@iconify/icons-eva/award-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Home',
    path: '/dashboard/home',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Folders',
    path: '/dashboard/folders',
    icon: getIcon(foldericon)
  },
  {
    title: 'Long repeat',
    path: '/dashboard/folder/longRepeat',
    icon: getIcon(longrepeatfill)
  },
  {
    title: 'Need repeat',
    path: '/dashboard/need-repeat',
    icon: getIcon(repeatfill)
  },
  {
    title: 'Saved words',
    path: '/dashboard/folder/saved',
    icon: getIcon(starfill)
  },
  {
    title: 'Books',
    path: '/dashboard/books',
    icon: getIcon(bookfill)
  },
  {
    title: 'Leaderboard',
    path: '/dashboard/leaderboard',
    icon: getIcon(ratefill)
  },
  {
    title: 'Leagues',
    path: '/dashboard/leagues',
    icon: getIcon(leaguesicon)
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon(gearfill)
  }
];

export default sidebarConfig;
