import axios from 'axios';
import config from '../config.json'

class Api {
  constructor() {
    this.api = axios.create({
      baseUrl: config.apiUrl
    });
  }

  async query(url, method, body = {}, options = { auth: true }) {
    try {
      const response = await this.api({
        url: config.apiUrl + url,
        method,
        data: body,
        headers: {
          Authorization: this.getToken()
        },
        ...options
      });
      return response.data
    }
    catch (error) {
      return {
        success: false,
        data: error
      };
    }
  }

  async get(url, options = {}) {
    return await this.query(url, "GET", {}, options);
  }

  async post(url, body = {}, options = {}) {
    return await this.query(url, "POST", body, options);
  }

  async put(url, body = {}, options = {}) {
    return await this.query(url, "PUT", body, options);
  }

  async delete(url, options = {}) {
    return await this.query(url, "DELETE", {}, options);
  }

  async updateUserData() {
    const { success, data } = await this.get('/user');
    if (success){
      this.setUser(data);
    }
  }
  async auth() {
    return await this.query('/auth', "GET", {}, {
      auth: true
    });
  }



  setUser(user) {
    localStorage.setItem(config.user_id, user.user_id);
    localStorage.setItem(config.authKey, user.user_token);
    localStorage.setItem(config.user_phone, user.user_phone_number);
    localStorage.setItem(config.user_full_name, user.user_full_name);
    localStorage.setItem(config.user_active_days, user.user_active_days);
    localStorage.setItem(config.check_auth, Date.now());
    return;
  }

  getToken() {
    return localStorage.getItem(config.authKey);
  }

  getUser() {
    return localStorage.getItem(config.user);
  }
  getUserId() {
    return localStorage.getItem(config.user_id);
  }
  getUserPhone() {
    return localStorage.getItem(config.user_phone);
  }
  getUserFullName() {
    return localStorage.getItem(config.user_full_name);
  }
  getUserActiveDays() {
    return localStorage.getItem(config.user_active_days);
  }

  checkIsPremuim(){
    return  axios.get(config.apiUrl + '/user-active-plans', {
      headers: {
        'Authorization': this.getToken()
      }
    }).then((response) => {
      if (response.data.success) {
        if (response.data.data.length === 0) {
          return false;
        }else{
          return true;
        }
      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          return false;
        }
      }
    });
  }
  logOut() {
    localStorage.clear();
    window.location.href = '/login';
    return;
  }

  chekAuth(){
    if (localStorage.getItem(config.check_auth) != null){
     return true;
    }
    return false;
  }
  async isAdmin() {
    let { success, data } = await this.auth();
    if (success) {
      return data?.data?.isAdmin;
    } else {
      return false;
    }
  }
};

export default new Api();