import { Box, Card, CardContent, Container, Grid, IconButton, Typography } from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import downIcon from '@iconify/icons-ant-design/down-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


export default function SentenceList() {
  const [cards, setCards] = useState([])
  const [wordlistInfo, setWordlistInfo] = useState([])

  let { wordlist_id } = useParams();
  const [refresh,setRefresh] = useState(1);
  let navigate = useNavigate();


  useEffect(() => {
    api.get('/wordlist/info?wordlist_id='+wordlist_id).then(function(response) { return response; })
        .then(function(resultData) {
          if (resultData.success){
            setWordlistInfo(resultData.data);
          }
        });

    axios.get(config.apiUrl+'/wordlist/words/flashcard?with_sentence=1', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id,
      },
    }).then((response)=>{
      if (response.data.success){
        setCards(response.data.data);
        api.updateUserData();
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });


  },[refresh]);


  const  play_sound = (item)=>{
    let error = 0;

    if (!item.has_audio){
      axios.get(config.apiUrl+'/word/get-audio?word_id='+item.id, {
        headers: {
          'Authorization': api.getToken()
        },
      }).then((response)=>{
        if (response.data.success){
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.id + '.mp3');
          player.play();
        }
      }).catch((error)=>{
        error = 1;
        if (error.response != undefined){
          if (error.response.status == 401){
            api.logOut();
          }else{
            alert(error.response.data.message);
          }
        }
      });
    }else {
      if (error == 0) {
        let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.id + '.mp3');
        player.play();
      }
    }
  }
  const action_saved = (word_id) => {
    axios.get(config.apiUrl+'/saved-word/'+word_id, {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response)=>{
      if (response.data.success){
        setRefresh(refresh*(-1));
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
  }
  return (
      <Page title="Folder view | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>

          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Flashcards</Typography>
          </Box>
          {cards.map(function(item, i) {
            return <Accordion sx={{ border:"1px solid #62626282" }}>
              <AccordionSummary
                  expandIcon={<Icon icon={downIcon} width={24} height={24}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                <Typography>{item.front.toUpperCase()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  item.sentences.map(function(example, j){
                      return <Typography key={j} dangerouslySetInnerHTML={{__html:example.sentence}}  sx={{ pb: 2, borderBottom:"1px solid #62626282" }}></Typography>
                  })
                }

              </AccordionDetails>
            </Accordion>;
          })}
        </Container>
      </Page>
  );
}