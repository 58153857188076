import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Stack, IconButton, Card, CardContent
} from '@mui/material';
import Page from "../components/Page";
import { useEffect, useState } from "react";
import config from "../config.json";
import api from "../api";
import axios from "axios";
import { useParams } from "react-router-dom";
import { shuffle } from '../utils/array';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import ReactionFooter from "../components/ReactionFooter";

export default function TrueOrFalse({type = ''}) {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState("");
  const [currentAnswer, setcurrentAnswer] = useState(null);
  const [lastId, setLastId] = useState(null);
  const [correctOption, setCorrectOption] = useState(null);
  const [chosenOption, setChosenOption] = useState(null);
  const [color1, setColor1] = useState('#ffffff');
  const [color0, setColor0] = useState('#ffffff');
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('');
  let { wordlist_id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    axios
        .get(config.apiUrl + "/wordlist/words"+type, {
          headers: {
            Authorization: api.getToken(),
          },
          params: {
            wordlist_id: wordlist_id,
          },
        })
        .then((response) => {
          if (response.data.success) {
            let data = shuffle(response.data.data);
            setWords(data);
            generateOptions(response.data.data[index], data);
            setTotalCount(data.length);
            setPendingCount(data.length);
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
  }, []);
  const generateOptions = (word, allWords) => {
    let rnd = Math.round(Math.random());
    setcurrentAnswer(rnd);
    if (rnd === 1){
      setCurrentWord(word["word1"]+' - '+word["word2"]);
    }else{
      let randomword = allWords.filter(w => w.word_id !== word.word_id && w.word_id !== lastId).slice(0, 1)[0];
      setLastId(randomword['word_id'])
      setCurrentWord(word["word1"]+' - '+randomword['word2']);
    }
  };

  const handleChoice = (e, answer) => {
    if (chosenOption!=null){return;}
    let point = 0;
    setChosenOption(answer);
    if (answer === currentAnswer){
      setSuccessCount(successCount + 1);
      point = 1;
      setStatus(1);
      if (answer === 1){
        setColor1('rgba(130,255,123,0.6)');
      }else{
        setColor0('rgba(130,255,123,0.6)');
      }
    }else{
      setErrorCount(errorCount + 1);
      point = -1;
      setStatus(-1);
      if (answer === 1){
        setColor1('#ff9f9f');
      }else{
        setColor0('#ff9f9f');
      }
    }

    axios
        .post(
            config.apiUrl + "/word/action/test",
            {
              word_id: words[index]["word_id"],
              point: point,
            },
            {
              headers: {
                Authorization: api.getToken(),
              },
            }
        )
        .then((response) => {
          if (response.data.success) {
            //window.location.href='/dashboard/folder/'+wordlist_id;
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              // alert(error.response.data.message);
            }
          }
        });
  };
  const handleKeyDown = (event) => {
    setChosenOption(null);
    setCorrectOption(null);
    setIndex(index + 1);
    if (index + 1 < words.length) {
      generateOptions(words[index + 1], words);
    }
    if (index + 1 == words.length) {
      setComplete(true);
    }
    setStatus(0);
    setColor0('#ffffff');
    setColor1('#ffffff');
  };
  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={4}>
              <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
                <Icon icon={backIcon} width={24} height={24}/>
              </IconButton>
            </Grid>
          </Grid>
          <br />
          <br />

          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              {complete === false ? (
                  <>
                    <Box sx={{ pb: 5 }}>
                      <Typography>Definition:</Typography>
                      <Typography variant="h4">{currentWord}</Typography>
                    </Box>
                    <br />
                    <div>
                      <Typography variant="p">
                        <b>Is it correct? </b>
                      </Typography>
                      <br />
                      <Grid container spacing={2}>

                            <Grid item xs={12} md={6}>
                              <Stack>
                                <Card sx={{ mb: 1 }}
                                      key={1}
                                      onClick={(e) => handleChoice(e, 1)}
                                      style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", cursor:'grab', border:"1px solid #62626282", backgroundColor:color1 }}>
                                  <CardContent>
                                    <Typography component="div">
                                      True
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Stack>
                            </Grid>
                          <Grid item xs={12} md={6}>
                              <Stack>
                                <Card sx={{ mb: 1 }}
                                      key={1}
                                      onClick={(e) => handleChoice(e, 0)}
                                      style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", cursor:'grab', border:"1px solid #62626282", backgroundColor:color0 }}>
                                  <CardContent>
                                    <Typography component="div">
                                      False
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Stack>
                            </Grid>


                      </Grid>
                      <Box  sx={{ py: 3 }}>
                        <ReactionFooter success={status} buttonHandle={handleKeyDown} text={message} type={'test'} />
                      </Box>
                    </div>
                    {error ? (
                        <Box sx={{ pb: 5 }} id={"helper"}>
                          <Typography variant="h4">Wrong answer</Typography>
                        </Box>
                    ) : null}
                  </>
              ) : (
                  <>
                    <center>
                      <Typography variant="h4">
                        Test completed <br /> Success: {successCount} Errors:{" "}
                        {errorCount}
                      </Typography>
                    </center>
                  </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}
