import {  Card, Typography,CardContent,Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function WordlistItem({item}) {
    let url = '/dashboard/wordlist/'+item.wordlist_id;
    let colorCard = '#ffffff';

    if (item.min_point == 0){
      colorCard = '#ffffff';
    }else if (item.min_point<0){
        colorCard = '#febfbf';
    }else if (item.min_point>0 && item.min_point < 10){
        colorCard = '#D9C7FF';
    }else if (item.min_point < 20){
        colorCard = '#C9DDFF';
    }else if (item.min_point < 30){
        colorCard = '#BEEFE8';
    }else{
        colorCard = '#D9F6C5';
    }

    return (
        <Link to={url} color="inherit" underline="none"  component={RouterLink}>
          <Card sx={{ minWidth: 275 }} style={{ backgroundColor: colorCard, boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", border:"1px solid #62626282" }}>
            <CardContent style={{ padding: '12px' }}>
              <Typography variant="h5" component="div">
                {item.wordlist_name}
              </Typography>
              <Typography variant="body2">
                {item.words_count} words
              </Typography>
            </CardContent>
          </Card>
        </Link>
    );
}