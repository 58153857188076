import {Box, Button, Container, Grid, IconButton, Stack, Typography} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import audioIcon from '@iconify/icons-ant-design/sound-fill';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom"
import ProgressBar from "../components/ProgressBar";

export default function WriteWithAudio() {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [currentWordTranslate, setCurrentWordTranslate] = useState('');
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [complate, setComplete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  let { wordlist_id } = useParams();
  let navigate = useNavigate();



  useEffect(() => {
    axios.get(config.apiUrl + '/wordlist/words', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id
      }
    }).then((response) => {
      if (response.data.success) {
        setWords(response.data.data,()=>{alert(1)});
        setCurrentWord(response.data.data[index]['word2']);
        setCurrentWordTranslate(response.data.data[index]['word1']);
        setTotalCount(response.data.data.length);
        setPendingCount(response.data.data.length);
      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          alert(error.response.data.message);
        }
      }
    });


  }, []);

  useEffect(() => {
    if (index>=0) {
      setTimeout(
          () => play_sound(),
          800
      );
    }
  }, [index]);

  const  play_sound = ()=>{
    let error = 0;
    let item = words[index];
    if(item) {
      if (!item.has_audio) {
        axios.get(config.apiUrl + '/word/get-audio?word_id=' + item.word_id, {
          headers: {
            'Authorization': api.getToken()
          },
        }).then((response) => {
          if (response.data.success) {
            let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
            player.play();
          }
        }).catch((error) => {
          error = 1;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
      } else {
        if (error == 0) {
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
          player.play();
        }
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      let point = 0;
      let inputWord = document.getElementById('input');
      if (inputWord.value.trim().toLowerCase() !== words[index]['word1'].trim().toLowerCase()) {
        point = -1;
        inputWord.value = '';
        setError(true);
        setTimeout(
            () => play_sound(),
            800
        );
        if (!error) {
          setErrorCount((errorCount + 1));
        }
      } else {
        if (!error) {
          point = 1;
          setSuccessCount((successCount + 1));
        }
        setError(false);
        if ((index + 2) > words.length) {
          setComplete(true);
        } else {
          setIndex((index + 1));
          setCurrentWord(words[index + 1]['word2']);
          setCurrentWordTranslate(words[index + 1]['word1']);
          inputWord.value = '';
        }
      }
      if (point !== 0) {
        axios.post(config.apiUrl + '/word/action/write',
            {
              word_id: words[index]['word_id'],
              point: point
            },
            {
              headers: {
                'Authorization': api.getToken()
              }
            }
        ).then((response) => {
          if (response.data.success) {
            //window.location.href='/dashboard/folder/'+wordlist_id;
          }
        }).catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              // alert(error.response.data.message);
            }
          }
        });
      }
    }
  }

  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <br /><br />

          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              {
                complate === false ?
                    <>
                      <Box sx={{ py: 3 }}>
                        <ProgressBar current={index} total={words.length} />
                        <Button
                            size="large"
                            style={{ width:70,height:70 }}
                            onClick={ () => play_sound() }
                            variant="contained"
                        >
                          <Icon icon={audioIcon} width={24} height={24}/>
                        </Button>
                      </Box>
                      <TextField sx={{ py: 3 }} id="input" variant="standard" onKeyDown={handleKeyDown} fullWidth autoFocus autoComplete={'off'} label={"The translation"} />
                      {
                        error ? <Box sx={{ py: 3 }} id={'helper'}>
                          <Typography variant="h4">{currentWordTranslate}</Typography>
                        </Box> : null

                      }
                      <Box  sx={{ py: 3 }}>
                        <Button size="large" variant="contained" onClick={handleKeyDown}>
                          Check
                        </Button>
                      </Box>
                    </>
                    :
                    <>
                      <center>
                        <Typography variant="h4">Write complated <br /> Success: {successCount} Errors: {errorCount}</Typography>
                      </center>
                    </>
              }
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}