import {Box, Card, CardContent, CardHeader, Container, Grid, IconButton, Link, Typography} from '@mui/material';
import Page from '../components/Page';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import {Link as RouterLink, useNavigate} from "react-router-dom";


import longrepeatfill from '@iconify/icons-eva/refresh-fill';

export default function Settings() {
  let navigate = useNavigate();

  return (
      <Page title="Leaderboard view | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <Card sx={{mt:3}}>
            <CardHeader title={ "Settings " } />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">

              <Link to={'/dashboard/settings/long-repeat'} color="inherit" underline="none"  component={RouterLink}>
                <Card sx={{ minWidth: 250 }} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 9px 0px, rgb(145 158 171 / 54%) 0px 5px 2px 0px", border:"1px solid #62626282" }}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      <Icon icon={longrepeatfill} width={24} height={24} style={{ marginRight: "10px" }}/>
                      Settings long repeat
                    </Typography>
                  </CardContent>
                </Card>
              </Link>

            </Box>
          </Card>


        </Container>
      </Page>
  );
}