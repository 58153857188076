import {Box, Button, Card, CardContent, Container, IconButton, Modal, Typography} from '@mui/material';
import Page from '../components/Page';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import api from '../api';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import {Icon} from '@iconify/react';
import {useNavigate} from "react-router-dom";
import {func} from "prop-types";

export default function ReadText() {
    const [readTexts, setReadTexts] = useState([])
    const [translation, setTranslation] = useState([])
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    let {wordlist_id} = useParams();
    let navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflowY:'auto',
        p: 4,
    };
    useEffect(() => {
        api.get('/read-texts?wordlist_id=' + wordlist_id).then(function (response) {
            return response;
        }).then(function (resultData) {
            if (resultData.success) {
                setReadTexts(resultData.data);
            }
        });

    }, []);

    const getTheWord = (word) => {
        setOpen(true);
        api.get('/en-uz/translation?word=' + word).then(function (response) {
            return response;
        }).then(function (resultData) {
            if (resultData.success) {
                let pronounciation;
                let cnt = 0;
                if (resultData.data.length > 0) {
                    let res = resultData.data.map(function (item, i) {
                        cnt++;
                        pronounciation = " [" + item.pronunciation + "]";
                        for (var k = 1; k <= 20; k++) {
                            if (item.translation.includes(k + ' ')) {
                                if (k > 1) {
                                    item.translation = item.translation.replace(k + ' ', '<br>' + k + '. ');
                                } else {
                                    item.translation = item.translation.replace(k + ' ', k + '. ');
                                }
                            } else {
                                break;
                            }
                        }
                        if (cnt == 1) {
                            return '<br>' + item.translation + "<hr>";
                        } else {
                            return item.translation + "<hr>";
                        }
                    }).join(' ');
                    setTranslation({ word:resultData.word+pronounciation,detail:res });
                }else{
                    setTranslation({ word:resultData.word,detail:"<hr><span style='color:red'>Sorry! The word was not found in our dictionary.</span>" });
                }
            }
        });
    }

    return (
        <Page title="Folder view | eagles.uz">
            <Container>
                <IconButton aria-label="fingerprint" color="success" onClick={() => navigate(-1)}>
                    <Icon icon={backIcon} width={24} height={24}/>
                </IconButton>
                <Box sx={{pb: 5}}>
                    <Typography variant="h4">Read texts</Typography>
                </Box>
                <Typography><br/></Typography>
                {readTexts.map(function (item, i) {
                    return <Card sx={{mb: 1}} key={i}>
                        <CardContent>
                            <Typography sx={{my: 3}} variant="h4">{item.text_title}</Typography>
                            <Typography variant="p" color="text.secondary" component="div" sx={{ whiteSpace: 'pre-wrap', fontSize: 17 }}>
                                {
                                    item.read_text.replace(/[\r\n]/g, " ").split(' ').map(
                                        function (word, j) {
                                            return <span onClick={(e) => getTheWord(word)} style={{cursor: "pointer"}}
                                                         key={j}>{word} </span>;
                                        }
                                    )
                                }
                            </Typography>
                        </CardContent>
                    </Card>
                })}

            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{my: 3}} variant="h4" component="string">{ translation.word }</Typography>
                    <Typography variant="string"  sx={{my: 3}} style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: translation.detail}}></Typography>
                </Box>
            </Modal>
        </Page>
    );
}