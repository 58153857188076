import { Typography } from '@mui/material';
import calendarFill from "@iconify/icons-eva/calendar-outline";
import {Icon} from "@iconify/react";
import api from "../../api";

export default function ActiveDays() {
    const activeDays = api.getUserActiveDays();
  return (
      <>
        <Typography variant="h4" noWrap valign="center" color={'#00ad4d'} style={{ paddingTop:"5px" }}>
          <Icon icon={calendarFill}  valign="center"/>
        </Typography>
        <Typography variant="h4" noWrap valign="center" color={'#00ad4d'} style={{ marginLeft:"0" }}>
            { activeDays??0 }
        </Typography>
      </>
  );
}
