import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

export const useRecaptcha = () => {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
    }, auth);
}

export const sendSms = async (phoneNumber) => {
    const auth = getAuth();
    const result = await signInWithPhoneNumber(auth, "+998" + phoneNumber, window.recaptchaVerifier);
    return result;
}