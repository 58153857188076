import {Box, Card, CardContent, Container, Grid, IconButton, Link, Typography} from '@mui/material';
import Page from '../components/Page';
import StudyActionButtons from '../components/custom/StudyActionButtons';
import { useEffect, useState } from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import { FlashcardArray } from 'react-quizlet-flashcard';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import nextIcon from '@iconify/icons-ant-design/arrow-right-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import iconSound from '@iconify/icons-ant-design/sound';
import starfill from '@iconify/icons-eva/star-fill';
import staroutline from '@iconify/icons-eva/star-outline';

export default function WordlistView() {
  const [cards, setCards] = useState([])
  const [wordlistInfo, setWordlistInfo] = useState([])
  let { wordlist_id } = useParams();
  const [refresh,setRefresh] = useState(1);
  let navigate = useNavigate();


  useEffect(() => {
    api.get('/wordlist/info?wordlist_id='+wordlist_id).then(function(response) { return response; })
        .then(function(resultData) {
          if (resultData.success){
            setWordlistInfo(resultData.data);
          }
        });


    axios.get(config.apiUrl+'/wordlist/words/flashcard', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id,
      },
    }).then((response)=>{
      if (response.data.success){
        setCards(response.data.data);
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });


  },[refresh]);


  const  play_sound = (item)=>{
    let error = 0;

    if (!item.has_audio){
      axios.get(config.apiUrl+'/word/get-audio?word_id='+item.id, {
        headers: {
          'Authorization': api.getToken()
        },
      }).then((response)=>{
        if (response.data.success){
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.id + '.mp3');
          player.play();
        }
      }).catch((error)=>{
        error = 1;
        if (error.response != undefined){
          if (error.response.status == 401){
            api.logOut();
          }else{
            alert(error.response.data.message);
          }
        }
      });
    }else {
      if (error == 0) {
        let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.id + '.mp3');
        player.play();
      }
    }
  }
  const action_saved = (word_id,original_wordlist_id) => {
    axios.get(config.apiUrl+'/saved-word/'+word_id+ "/" + original_wordlist_id , {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response)=>{
      if (response.data.success){
        setRefresh(refresh*(-1));
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
  }
  return (
      <Page title="Folder view | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>

          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Flashcards</Typography>
          </Box>
          {wordlist_id.substr(0,10)!=="longRepeat"?
              <center>
                <FlashcardArray cards={cards} />
              </center>
              :""}

          <Typography><br/></Typography>
          <StudyActionButtons item={wordlist_id} wordslistInfo={wordlistInfo}/>
          <Typography sx={{my:3}} variant="h3">Words</Typography>
          {cards.map(function(item, i) {
            return <Card sx={{ mb: 1 }} key={i} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", border:"1px solid #62626282" }}>
              <CardContent style={{ paddingTop:"10px", paddingBottom:"10px" }}>
                <Typography component="div">
                  <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                      <IconButton aria-label="Sound" onClick={ () => play_sound(item) }>
                        <Icon icon={iconSound} width={24} height={24}/>
                      </IconButton>
                      {item.front}</Grid>
                    <Grid item sm={5} xs={12}>{item.back}</Grid>
                    <Grid item sm={2} xs={12} style={{ textAlign:'right' }}>
                      {item.point} point
                      <IconButton aria-label="Sound" onClick={ () => action_saved(item.id, item.original_wordlist_id) }>
                        <Icon icon={item.saved=='0' ? staroutline:starfill } color="orange" width={30} height={30} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
            </Card>;
          })}
          {/*<Grid container spacing={3} sx={{mt:1}}>*/}
          {/*<Grid item xs={6} md={6} lg={6}  padding={1} key={11}>*/}
          {/*  <Link to={'asdad'} color="inherit" underline="none" component={RouterLink}>*/}
          {/*    <Card sx={{ minWidth: 250 }} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 5px 0px, rgb(145 158 171 / 54%) 0px 3px 2px 0px", border:"1px solid #62626282" }}>*/}
          {/*      <CardContent>*/}
          {/*        <Typography variant="h5" component="div">*/}
          {/*          <Icon icon={backIcon} width={24} height={24} style={{ marginRight: "10px" }}/>*/}
          {/*          asdasdads*/}
          {/*        </Typography>*/}
          {/*      </CardContent>*/}
          {/*    </Card>*/}
          {/*  </Link>*/}
          {/*</Grid>*/}
          {/*  <Grid item xs={6} md={6} lg={6}  padding={1} key={12}>*/}
          {/*  <Link to={'asdad'} color="inherit" underline="none" component={RouterLink}>*/}
          {/*    <Card sx={{ minWidth: 250 }} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 5px 0px, rgb(145 158 171 / 54%) 0px 3px 2px 0px", border:"1px solid #62626282" }}>*/}
          {/*      <CardContent>*/}
          {/*        <Typography variant="h5" component="h5">*/}
          {/*          <div>*/}
          {/*            asdasdads*/}
          {/*            <Icon icon={nextIcon} width={24} height={24} style={{ marginTop: "0px" }}/>*/}
          {/*          </div>*/}

          {/*        </Typography>*/}
          {/*      </CardContent>*/}
          {/*    </Card>*/}
          {/*  </Link>*/}
          {/*</Grid>*/}
          {/*</Grid>*/}

        </Container>
      </Page>
  );
}