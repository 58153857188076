import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    const auth = getAuth();
    try {
        const result = await signInWithPopup(auth, provider);
        return result;
    } catch (error) {
        console.log(error);
        return null;
    }
};
