import {Box, Card, CardHeader, Container, Drawer, IconButton} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import api from '../api';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import {useNavigate, useParams} from "react-router-dom";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Scrollbar from "../components/Scrollbar";

export default function LeagueUsers() {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  let {league_id} = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    loadData();

  },[]);

  useEffect(() => {
    loadData();
  },[page]);

  const loadData = () => {
    api.get('/league/'+league_id+'/users?page='+page).then(function(response) { return response; })
        .then(function(resultData) {
          if (resultData.success){
            setData(resultData.data);
          }
        });
  }
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (

      <Page title="Leaderboard view | eagles.uz">

        <Container >
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>

          <Card sx={{mt:3, maxWidth: '90vw', overflowX:'auto'}}>
            <CardHeader title={ "League for "+data.period } />

              <Box sx={{ p: 3, pb: 1 }} dir="ltr" >
                <h4 sx={{ color: 'success' }}>Data updated every 10 minutes!</h4>
                <div>
                  <Table sx={{ width: '100%' }} aria-label="table bordered">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">№</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="center">Words</TableCell>
                      <TableCell align="right">F</TableCell>
                      <TableCell align="right">R</TableCell>
                      <TableCell align="right">FR points</TableCell>
                      <TableCell align="right">Points</TableCell>
                      <TableCell align="right">Tests</TableCell>
                      <TableCell align="right">Writes</TableCell>
                      <TableCell align="right">Speeches</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data?.map((row) => (
                        <TableRow
                            key={row.user_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            selected={ api.getUserId() == row.user_id?true:false }
                        >
                          <TableCell align="center">{row.rn}</TableCell>
                          <TableCell component="th" scope="row">{row.user_full_name}</TableCell>
                          <TableCell align="right">{row.total_words_count}</TableCell>
                          <TableCell align="right">{row.total_fully_rate}</TableCell>
                          <TableCell align="right">{row.rate}</TableCell>
                          <TableCell align="right">{row.total_rate_point}</TableCell>
                          <TableCell align="right">{row.total_points}</TableCell>
                          <TableCell align="right">{row.total_tests_count}</TableCell>
                          <TableCell align="right">{row.total_writes_count}</TableCell>
                          <TableCell align="right">{row.total_speeches_count}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
                </div>
                <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
              </Box>
          </Card>
        </Container>
      </Page>
  );
}