import { Box, Container, Grid, Typography, Button as MuiButton, Stack, IconButton } from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { shuffle } from '../utils/array';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";



export default function Match() {
  const [words, setWords] = useState([]);
  const [leftSide, setLeftSide] = useState([]);
  const [leftChoosen, setLeftChoosen] = useState('');
  const [rightSide, setRightSide] = useState([]);
  const [rightChoosen, setRightChoosen] = useState('');
  const [finished, setFinished] = useState(0);
  const [errors, setErrors] = useState([]);
  const [corrects, setCorrects] = useState([]);
  const [mistakes, setMistakes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  let { wordlist_id } = useParams();
  let navigate = useNavigate();


  useEffect(() => {
    axios.get(config.apiUrl + '/wordlist/words', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id
      }
    }).then((response) => {
      if (response.data.success) {
        let data = shuffle(response.data.data);
        setWords(data);
        generatePairs(data, 5);
        setTotalCount(5);
        setPendingCount(5);
      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          alert(error.response.data.message);
        }
      }
    });
  }, []);

  const generatePairs = (allWords, size = 5) => {
    let limitedWords = shuffle(allWords).slice(0, size);
    let left = [];
    let right = [];
    for (let word of limitedWords) {
      left.push({ id: word.word_id, text: word.word1 });
      right.push({ id: word.word_id, text: word.word2 });
    }
    right = shuffle(right);
    setLeftSide(left);
    setRightSide(right);
  }

  const isPair = (first, second) => {
    let firstId = leftSide.find(word => word.text === first)?.id;
    let secondId = rightSide.find(word => word.text === second)?.id;
    if (!firstId || !secondId) return false;
    return firstId === secondId;
  }

  const handleLeftChange = (e) => {
    setLeftChoosen(e.target.value);
  }

  const handleRightChange = (e) => {
    if (!leftChoosen) return;
    setRightChoosen(e.target.value);
    checkPairs(leftChoosen, e.target.value);
  }

  const checkPairs = (first, second) => {
    let point = 0;

    if (isPair(first, second)) {
      if (!mistakes.includes(first)) {
        point = 1;
        setSuccessCount(successCount + 1);
      }
      setCorrects([
        first,
        second,
        ...corrects
      ])
      setLeftChoosen('');
      setFinished(finished + 1);
    } else {
      if (!mistakes.includes(first)) {
        setMistakes([
          first,
          ...mistakes,
        ]);
        setErrorCount(errorCount + 1);
      }
      setErrors([
        second
      ])
      point = -1;
      setTimeout(() => {
        setErrors(errors.filter(e => e !== second));
      }, 3000);
    }

    if (point !== 0) {
      // send result to backend
      axios.post(config.apiUrl + '/word/action/match',
          {
            word_id: words.find(w => w.word1 === first)?.word_id,
            point: point
          },
          {
            headers: {
              'Authorization': api.getToken()
            }
          }
      ).then((response) => {
        if (response.data.success) {
          //window.location.href='/dashboard/folder/'+wordlist_id;
        }
      }).catch((error) => {
        if (error.response != undefined) {
          if (error.response.status == 401) {
            api.logOut();
          } else {
            // alert(error.response.data.message);
          }
        }
      });
    }

    // setLeftChoosen('');
    setRightChoosen('');
  }

  let btnStyle = {
    width: "100%",
    height: 20,
    border: "1px solid #d9e2ef",
    // backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "text.primary",
    p: 3,
    "&:hover": {
      backgroundColor: "white",
      border: "1px solid #000000",
      color: "text.primary",
    },
    "&:active": {
      backgroundColor: "white",
      color: "text.primary",
    },
    "&:focus": {
      backgroundColor: "white",
      color: "text.primary",
    },
  };

  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <br /><br />

          <Grid container spacing={2}>
            <Grid item sm={3} xs={12}>
              <Typography variant="h4">Success {successCount}</Typography>
              <LinearProgress value={successCount / totalCount * 100} color={"success"} variant={"determinate"} /><br />
              <Typography variant="h4">Pending {(totalCount - successCount - errorCount)}</Typography>
              <LinearProgress value={(totalCount - (successCount + errorCount)) / totalCount * 100} color={"info"} variant={"determinate"} /><br />
              <Typography variant="h4">Errors {errorCount}</Typography>
              <LinearProgress value={errorCount / totalCount * 100} color={"error"} variant={"determinate"} /><br />
            </Grid>
            <Grid item sm={9} xs={12}>
              {
                finished !== totalCount ?
                    <>
                      <div>
                        <Box sx={{ pb: 3 }}>
                          <Typography variant="h5">
                            <b>Choose correct pair of words</b>
                          </Typography>
                        </Box>
                        <br />
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                          <Stack
                              spacing={1}
                              xs={4}
                              justifyContent="center"
                              alignItems="center"
                              style={{ px:"5px"}}
                              sx={{ width: "50%" }}
                          >
                            {leftSide.map((word, num) => (
                                <Box style={{width:"100%"}} p={"5px"}>
                                  <MuiButton
                                      disabled={corrects.includes(word.text)}
                                      fullWidth
                                      xs={12}
                                      sx={btnStyle}
                                      variant="outlined"
                                      style={{
                                        color: leftChoosen === word.text ? "#84ffff" : (
                                            corrects.includes(word.text) ? "#76ff03" : "black"
                                        ),
                                        borderColor: leftChoosen === word.text ? "#18ffff" : "#e0e0e0",
                                      }}
                                      onClick={handleLeftChange}
                                      value={word.text}
                                  >
                                    {word.text}
                                  </MuiButton>
                                </Box>
                            ))}
                          </Stack>

                          <Stack
                              spacing={1}
                              xs={4}
                              justifyContent="center"
                              alignItems="center"
                              sx={{ width: "50%" }}
                          >
                            {rightSide.map((word, num) => (
                                <Box style={{width:"100%"}} p={"5px"}>
                                  <MuiButton
                                      disabled={corrects.includes(word.text)}
                                      fullWidth
                                      xs={12}
                                      sx={btnStyle}
                                      variant="outlined"
                                      style={{
                                        fontSize: 12,
                                        color: rightChoosen === word.text ? "#69f0ae" : (
                                            errors.includes(word.text) ? "#ff6e40" : (
                                                corrects.includes(word.text) ? "#76ff03" : "black"
                                            )
                                        ),
                                        borderColor: rightChoosen === word.text ? "#00e676" : "#e0e0e0",
                                      }}
                                      onClick={handleRightChange}
                                      value={word.text}
                                  >
                                    {word.text}
                                  </MuiButton>
                                </Box>
                            ))}
                          </Stack>
                        </Grid>
                      </div>
                    </>
                    :
                    <>
                      <center>
                        <Typography variant="h4">Write complated <br /> Success: {successCount} Errors: {errorCount}</Typography>
                      </center>
                    </>
              }
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}