import {Box, Card, CardHeader, Container, Divider, Modal} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import CardMedia from '@mui/material/CardMedia';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import api from '../api';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import {useNavigate, useParams} from "react-router-dom";
import Pagination from '@mui/material/Pagination';

export default function BookRead() {
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [showData, setShowData] = useState(false);
    const [translation, setTranslation] = useState([])
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    let { book_id } = useParams();
    let navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxHeight:600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow:'auto',
        p: 4,
    };
    useEffect(() => {
        api.get('/user-book-page/'+book_id).then(function(response) { return response; })
            .then(function(resultData) {
                if (resultData.success){
                    setData(resultData.data);
                    setPage(resultData.data.current_page);
                    setShowData(true);
                }
            });
    },[]);
    useEffect(() => {
        loadData();
    },[page]);

    const loadData = () => {
        if (showData) {
            api.get('/book/' + book_id + '?page=' + page).then(function (response) {
                return response;
            }).then(function (resultData) {
                if (resultData.success) {
                    setData(resultData.data);
                }
            });
        }
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const getTheWord = (word) => {
        setOpen(true);
        api.get('/en-uz/translation?word=' + word).then(function (response) {
            return response;
        }).then(function (resultData) {
            if (resultData.success) {
                let pronounciation;
                let cnt = 0;
                if (resultData.data.length > 0) {
                    let res = resultData.data.map(function (item, i) {
                        cnt++;
                        pronounciation = " [" + item.pronunciation + "]";
                        for (var k = 1; k <= 20; k++) {
                            if (item.translation.includes(k + ' ')) {
                                if (k > 1) {
                                    item.translation = item.translation.replace(k + ' ', '<br>' + k + '. ');
                                } else {
                                    item.translation = item.translation.replace(k + ' ', k + '. ');
                                }
                            } else {
                                break;
                            }
                        }
                        if (cnt == 1) {
                            return '<br>' + item.translation + "<hr>";
                        } else {
                            return item.translation + "<hr>";
                        }
                    }).join(' ');
                    setTranslation({ word:resultData.word+pronounciation,detail:res });
                }else{
                    setTranslation({ word:resultData.word,detail:"<hr><span style='color:red'>Sorry! The word was not found in our dictionary.</span>" });
                }

            }
        });
    }

    return (
        <Page title="Books | eagles.uz">
            <Container>
                <Card sx={{mt:3}}>
                    <CardHeader title={ "Books reading"} />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
                    </Box>
                    {data?.data?.map((row) => (
                        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                            <Badge color="primary" badgeContent={row.book_level}>
                                <Typography component="div" variant="h5">
                                    {row.chapter_name}
                                </Typography>
                            </Badge>
                            <Typography variant="p" color="text.secondary" component="div" sx={{ whiteSpace: 'pre-wrap', fontSize: 17 }}>
                                {
                                    ("\n"+row.chapter_text).replace(/[\r\n]/g, " \n\t").split(' ').map((s,i, word_array) => {
                                        if (s === "\n\t" && word_array[i-1] ==="\n\t"){
                                            return '';
                                        }
                                        return <span onClick={() => getTheWord(s)} key={i} style={{cursor: "pointer"}}>{s} </span>
                                    })
                                }
                            </Typography>
                        </Box>
                    ))}

                    <Divider sx={{ m:1 }} />
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
                    </Box>
                </Card>


            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" sx={{my: 3}} variant="h4" component="string">{ translation.word }</Typography>
                    <Typography variant="string"  sx={{my: 3}} style={{fontSize:"20px"}} dangerouslySetInnerHTML={{ __html: translation.detail}}></Typography>
                </Box>
            </Modal>
        </Page>
    );
}