import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------


export default function AppActiveDays({chart_data}) {
  const CHART_DATA = [
    {
      name: '',
      type: 'area',
      data: chart_data.data
    }
  ];
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [1, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: chart_data.label,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} point`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card sx={{mt:3}}>
      <CardHeader title="Points of days" /*subheader="(+12%) than last day" *//>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
