import { Button, Card, CardActions, CardActionArea, Typography,CardContent,Link } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { Link as RouterLink } from 'react-router-dom';

export default function FolderItem({item}) {
  let url = '/dashboard/folder/'+item.folder_id
  return (
    <Link to={url} color="inherit" underline="none"  component={RouterLink}>
      <Card sx={{ maxWidth: 345 }} style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", border:"1px solid #62626282" }}>
        <CardActionArea>
          <CardMedia
              component="img"
              width="345"
              image={item.folder_image}
              alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item.folder_name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}