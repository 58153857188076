import { Icon } from '@iconify/react';
import appleFilled from '@iconify/icons-ant-design/paper-clip-outline';
// material
import { alpha, styled } from '@mui/material/styles';
import {Card, Link, Typography} from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import {Link as RouterLink} from "react-router-dom";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.secondary.darker,
  backgroundColor: theme.palette.secondary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.secondary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.secondary.dark, 0)} 0%, ${alpha(
      theme.palette.secondary.dark,
      0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppTotalWords({total}) {
  return (
      <Link to={'/dashboard/folders'} color="inherit" underline="none"  component={RouterLink}>
        <RootStyle>
          <IconWrapperStyle>
            <Icon icon={appleFilled} width={24} height={24} />
          </IconWrapperStyle>
          <Typography variant="h3">{fShortenNumber(total)}</Typography>
          <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
            Words
          </Typography>
        </RootStyle>
      </Link>
  );
}
