import {
  Box,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Stack, IconButton, Card, CardContent
} from '@mui/material';
import Page from "../components/Page";
import { useEffect, useState } from "react";
import config from "../config.json";
import api from "../api";
import axios from "axios";
import { useParams } from "react-router-dom";
import { shuffle } from '../utils/array';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import ReactionFooter from "../components/ReactionFooter";
import ProgressBar from "../components/ProgressBar";

export default function Test({type = ''}) {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState("");
  const [currentWordTranslate, setCurrentWordTranslate] = useState("");
  const [currentOptions, setCurrentOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);
  const [chosenOption, setChosenOption] = useState(null);
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [arrow, setArrow] = useState(false);
  const [complete, setComplete] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('');
  let { wordlist_id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    loadDataAPI();
  }, []);

  useEffect(()=>{
    if (chosenOption != null){
      document.addEventListener('keydown',     function(event) {
        if (event.key === 'Enter') {
          setChosenOption(null);
          setCorrectOption(null);
          setIndex(index + 1);
          if (index + 1 < words.length) {
            generateOptions(words[index + 1], words);
          }
          if (index + 1 == words.length) {
            setComplete(true);
          }
          setStatus(0);
        }
      });
    }
  },[chosenOption]);
  useEffect(()=>{
    if (complete){
      document.addEventListener('keydown',     function(event) {
        if (event.key === 'Enter') {
          setComplete(false);
          setIndex(0);
          setChosenOption(null);
          setCorrectOption(null);
          loadDataAPI();
        }
      });
    }
  },[complete])

  const loadDataAPI = ()=>{
    axios
        .get(config.apiUrl + "/wordlist/words", {
          headers: {
            Authorization: api.getToken(),
          },
          params: {
            wordlist_id: wordlist_id,
          },
        })
        .then((response) => {
          if (response.data.success) {
            let response_data = response.data.data;
            let response_data2 = response_data.sort((a, b) => (a.point - b.point)).map(function (el, index) {
              return response_data[index];
            });
            let data;
            if (type === "unstudied"){
              data = response_data2.slice(0,5);
            }else {
              data = shuffle(response_data);

            }
            setWords(data);
            generateOptions(response.data.data[index], data);
            setTotalCount(data.length);
            setPendingCount(data.length);
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
  }
  const handleChangeArrow = (event)=>{

    setChosenOption(null);
    setCorrectOption(null);
    setIndex(0);
    if (index + 1 < words.length) {
      generateOptions(words[index], words);
    }
    if (index + 1 == words.length) {
      setComplete(true);
    }

    setArrow(event.target.checked);



  }
  const generateOptions = (word, allWords) => {
    setCurrentWord(word["word2"]);
    setCurrentWordTranslate(word["word1"]);
    let optionCards = shuffle([
      word,
      ...shuffle(allWords.filter(w => w.word_id !== word.word_id)).slice(0, 3)
    ]);
    setCurrentOptions(optionCards);
  };

  const handleChoice = (e, choice, i) => {
    if (chosenOption!=null){return;}
    setChosenOption(i);
    setCorrectOption(currentOptions.findIndex(w => w.word1 === currentWordTranslate));
    let point = 0;
    if (choice.word1 === currentWordTranslate) {
      setSuccessCount(successCount + 1);
      point = 1;
      setStatus(1);
    } else {
      setErrorCount(errorCount + 1);
      point = -1;
      setStatus(-1);
    }
    play_sound();
    axios
        .post(
            config.apiUrl + "/word/action/test",
            {
              word_id: words[index]["word_id"],
              point: point,
            },
            {
              headers: {
                Authorization: api.getToken(),
              },
            }
        )
        .then((response) => {
          if (response.data.success) {
            //window.location.href='/dashboard/folder/'+wordlist_id;
          }
        })
        .catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              // alert(error.response.data.message);
            }
          }
        });
  };
  const handleKeyDown = (event) => {
    console.log(chosenOption);
    if (chosenOption === null){return;}
    setChosenOption(null);
    setCorrectOption(null);
    setIndex(index + 1);
    if (index + 1 < words.length) {
      generateOptions(words[index + 1], words);
    }
    if (index + 1 == words.length) {
      setComplete(true);
    }
    setStatus(0);
  };


  const  play_sound = ()=>{
    let error = 0;
    let item = words[index];
    if(item) {
      if (!item.has_audio) {
        axios.get(config.apiUrl + '/word/get-audio?word_id=' + item.word_id, {
          headers: {
            'Authorization': api.getToken()
          },
        }).then((response) => {
          if (response.data.success) {
            let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
            player.play();
          }
        }).catch((error) => {
          error = 1;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
      } else {
        if (error == 0) {
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
          player.play();
        }
      }
    }
  }

  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={4}>
              <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
                <Icon icon={backIcon} width={24} height={24}/>
              </IconButton>
            </Grid>
            <Grid item sm={4} xs={8} align={'right'}>
              <FormControlLabel control={<Switch align={'right'} onChange={handleChangeArrow} />} label="Word/Translation" />
            </Grid>
          </Grid>
          <br />
          <br />

          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              {complete === false ? (
                  <>
                    <Box sx={{ pb: 5 }}>
                      <ProgressBar current={index} total={words.length} />
                      <Typography>Definition:</Typography>
                      <Typography variant="h4">{arrow?currentWord:currentWordTranslate}</Typography>
                    </Box>
                    <div>
                      <Typography variant="p">
                        <b>Select the correct term</b>
                      </Typography>
                      <Grid container spacing={2}>
                        {currentOptions.map((option, num) => (
                            <Grid item xs={12} md={6} key={num}>
                              <Stack>
                                <Card sx={{ mb: 1 }}
                                      key={1}
                                      onClick={(e) => handleChoice(e, option, num)}
                                      style={{ boxShadow:"rgb(145 158 171 / 34%) 0px 0px 2px 0px, rgb(145 158 171 / 54%) 0px 3px 0px 0px", cursor:'grab',  border:"1px solid #62626282", backgroundColor:(correctOption !== null ? (correctOption === num ? "rgba(130,255,123,0.6)" : (chosenOption === num ? "#ff9f9f" : "#ffffff")) : "#ffffff") }}>
                                  <CardContent style={{ padding: '12px' }}>
                                    <Typography component="div">
                                      {arrow?option.word1:option.word2}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Stack>
                            </Grid>
                        ))}


                      </Grid>
                      <Box  sx={{ py: 3 }}>
                        <ReactionFooter success={status} buttonHandle={handleKeyDown} text={message} type={'test'} />
                      </Box>
                    </div>
                    {error ? (
                        <Box sx={{ pb: 5 }} id={"helper"}>
                          <Typography variant="h4">{currentWordTranslate}</Typography>
                        </Box>
                    ) : null}
                  </>
              ) : (
                  <>
                    <center>
                      <Typography variant="h4">
                        Test completed <br /> Success: {successCount} Errors:{" "}
                        {errorCount}
                      </Typography>
                    </center>
                  </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}
