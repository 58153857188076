import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Modal, Button, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import api from 'src/api';
import config from '../../../config.json'
import { sendSms, useRecaptcha } from 'src/firebase/phone';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState(null);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const [confirmResult, setResult] = useState(null);
  const [smsCode, setSmsCode] = useState(null);

  useEffect(() => {
    useRecaptcha();
  }, []);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    phone: Yup.string().max(9).required('Phone is required'),
    password: Yup.string().required('Password is required')
  });

  const continueSubmit = async () => {
    setOpen(false);
    try {
      await confirmResult.confirm(smsCode)
      const data = {
        phone: userData.phone,
        password: userData.password,
        fullName: userData.firstName + " " + userData.lastName
      }
      axios.post(config.apiUrl + '/register', data, {
        headers: {
          "X-App-Token": config.appToken
        }
      }).then((response) => {
        if (response.data.success) {
          api.setUser(response.data.data);
          navigate('/dashboard/home');
          formik.setSubmitting(false);
        }
        formik.setSubmitting(false);
      }).catch((error) => {
        if (error.response != undefined) {
          alert(error.response.data.message);
          formik.setSubmitting(false);
        }
      })
    } catch (error) {
      alert('Invalid code');
    };
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (formValues) => {
      console.log(formValues);
      setUserData(formValues);
      const result = await sendSms(formValues.phone);
      setResult(result);
      setOpen(true);
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="First name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Last name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="Phone number"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <div id="recaptcha-container"></div>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Register
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              We've sent a sms code to <br />
              +(998)-{values.phone}
            </Typography>
            <br />
            <TextField
              fullWidth
              type="number"
              label="Code"
              defaultValue={smsCode}
              onChange={e => setSmsCode(e.target.value)}
            />
            <br />
            <br />
            <Button
              fullWidth
              variant="contained"
              onClick={continueSubmit}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
      </div>
    </div>
  );
}
