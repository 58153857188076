import {Box, Button, Container, Grid, IconButton, Typography} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom"
import ReactionFooter from "../components/ReactionFooter";
import ProgressBar from "../components/ProgressBar";
import {shuffle} from "../utils/array";

export default function Write({type = ''}) {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [currentWordTranslate, setCurrentWordTranslate] = useState('');
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [complate, setComplete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState('');
  let { wordlist_id } = useParams();
  let iscomplate = false;
  let navigate = useNavigate();


  const loadDataAPI = ()=>{
    axios.get(config.apiUrl + '/wordlist/words', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id
      }
    }).then((response) => {
      if (response.data.success) {
        setIndex(0);
        setErrorCount(0);
        setSuccessCount(0);
        setComplete(false);
        iscomplate = false;
        setStatus(0);
        setMessage('');

        let response_data = response.data.data;
        let response_data2 = response_data.sort((a, b) => (a.point - b.point)).map(function (el, index) {
          return response_data[index];
        });
        let data;
        if (type === "unstudied"){
          data = response_data2.slice(0,5);
        }else {
          data = shuffle(response_data);
        }
        console.log(data);
        setWords(data);
        setCurrentWord(data[0]['word2']);
        setCurrentWordTranslate(data[0]['word1']);
        setTotalCount(data.length);
        setPendingCount(data.length);
        let inputWord = document.getElementById('input');
        inputWord.focus();
      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          alert(error.response.data.message);
        }
      }
    });
  }
  useEffect(() => {
    loadDataAPI();
  }, []);

  const keyDownHandler = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (event.srcElement.nodeName != 'INPUT' && complate){
        loadDataAPI();
      }
    }
  };

  useEffect(() => {
    console.log(complate);
    document.addEventListener('keydown', keyDownHandler);
  }, [complate]);

  const handleKeyDown = (event) => {
    //console.log(event.key);
    if (event.key === 'Enter' || event.type === 'click') {

      let point = 0;
      let inputWord = document.getElementById('input');
      inputWord.focus();
      if (status === 1){
        setIndex((index + 1));
        setCurrentWord(words[index + 1]['word2']);
        setCurrentWordTranslate(words[index + 1]['word1']);
        setMessage('');
        setStatus(0);
        inputWord.value = '';
        return;
      }
      if (inputWord.value.trim().toLowerCase() === ""){
        return;
      }
      if ( inputWord.value.trim().toLowerCase() !== words[index]['word1'].trim().toLowerCase()) {
        point = -1;
        inputWord.value = '';
        setError(true);
        setStatus(-1);
        setMessage('Answer: '+currentWordTranslate.toUpperCase());
        if (!error) {
          setErrorCount((errorCount + 1));
        }
      } else {
        if (!error) {
          point = 1;
          setSuccessCount((successCount + 1));
        }
        setStatus(1);
        setMessage('Success');
        setError(false);
        if ((index + 2) > words.length) {
          setComplete((complate)=>true);
        }
      }

      if (point !== 0) {
        play_sound();
        axios.post(config.apiUrl + '/word/action/write',
            {
              word_id: words[index]['word_id'],
              point: point
            },
            {
              headers: {
                'Authorization': api.getToken()
              }
            }
        ).then((response) => {
          if (response.data.success) {
            //window.location.href='/dashboard/folder/'+wordlist_id;
          }
        }).catch((error) => {
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              // alert(error.response.data.message);
            }
          }
        });
      }
    }
  }

  const  play_sound = ()=>{
    let error = 0;
    let item = words[index];
    if(item) {
      if (!item.has_audio) {
        axios.get(config.apiUrl + '/word/get-audio?word_id=' + item.word_id, {
          headers: {
            'Authorization': api.getToken()
          },
        }).then((response) => {
          if (response.data.success) {
            let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
            player.play();
          }
        }).catch((error) => {
          error = 1;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
      } else {
        if (error == 0) {
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
          player.play();
        }
      }
    }
  }

  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <br /><br />

          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} >
              {
                complate === false ?
                    <>
                      <Box sx={{ py: 3 }}>
                        <ProgressBar current={index} total={words.length} />
                        <Typography>Definition:</Typography>
                        <Typography variant="h4">{currentWord}</Typography>
                      </Box><br/>
                      <TextField sx={{ py: 3 }} id="input" variant="standard" onKeyDown={handleKeyDown} fullWidth autoFocus autoComplete={'off'} label={"The translation"} />
                      <Box  sx={{ py: 3 }}>
                        <ReactionFooter success={status} buttonHandle={handleKeyDown} text={message} />
                      </Box>
                    </>
                    :
                    <>
                      <center>
                        <Typography variant="h4">Write complated <br /> Success: {successCount} Errors: {errorCount}</Typography>
                        <Button size="large" sx={{ mt: 3 }} variant="contained" fullWidth onClick={loadDataAPI}>Restart </Button>
                      </center>
                    </>
              }
            </Grid>
          </Grid>
        </Container>

      </Page>

  );
}