import {Button, Card, CardHeader, Container, Grid, IconButton} from '@mui/material';
import Page from '../components/Page';
import {forwardRef, useEffect, useState} from 'react';
import api from '../api';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import { Icon } from '@iconify/react';
import { useNavigate} from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SettingsLongRepeat() {
    const [data, setData] = useState({wordlists:[],long_repeat_per_wordlist:0});
    const [alertopen, setAlertopen] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    },[]);

    const loadData = () => {
        api.get('/wordlists-long-repeat').then(function(response) { return response; })
            .then(function(resultData) {
                if (resultData.success){
                    setData(resultData.data);
                    var selecteds = [];
                    resultData.data.wordlists?.forEach((row)=>{
                        if (row.checked==1){
                            selecteds = [...selecteds,row.wordlist_id];
                        }
                    });
                    setRowSelectionModel(selecteds);
                }
            });
    }

    const saveData = (e)=>{
        api.post('/wordlists-long-repeat',
            {
                wordlist_ids:rowSelectionModel,
                long_repeat_per_wordlist:data.long_repeat_per_wordlist
            }
        ).then(function(response) { return response; })
            .then(function(resultData) {
                setAlertopen(resultData.success);
            });
    }
    const columns: GridColDef[] = [
        { field: "folder_name", headerName: "Folder name", width: 250 },
        { field: "wordlist_name", headerName: "Wordlist name", width: 250 }
    ];

    return (
        <Page title="Leaderboard view | eagles.uz">
            <Container>
                <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                        <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
                            <Icon icon={backIcon} width={24} height={24}/>
                        </IconButton>
                    </Grid>
                    <Grid item sm={8} xs={12}  style={{ textAlign:'right' }}>
                        <TextField
                            id="outlined-required"
                            label="Word count per wordlist"
                            type="number"
                            value={data.long_repeat_per_wordlist}
                            onChange={e => setData((prevState) => ({
                                    ...prevState,
                                    long_repeat_per_wordlist: e.target.value
                                }
                            ))}
                            sx={{ mr: 2 }}
                        />
                        <Button
                            margin={"10px"}
                            variant="contained"
                            onClick={saveData}
                        >
                            Save
                        </Button>
                        <Button
                            sx={{m:1}}
                            variant="contained"
                            href={'/dashboard/folder/longRepeat'}
                        >
                            Go to a practice
                        </Button>

                    </Grid>
                </Grid>


                <Card sx={{mt:3}}>
                    <CardHeader title={ "Settings for long repeat" } >
                        asdasd
                    </CardHeader>

                    <DataGrid
                        checkboxSelection
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                        autoHeight
                        rows={data?.wordlists}
                        columns={columns}
                    />
                </Card>
            </Container>
            <Snackbar open={alertopen} autoHideDuration={4000} onClose={(e)=>{setAlertopen(false)}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert severity="success" sx={{ width: '100%' }}>
                    Data saved successfully!
                </Alert>
            </Snackbar>
        </Page>
    );
}