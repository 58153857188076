import {Box, Card, CardHeader, Container, Link} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import api from '../api';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function Books() {
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [level, setLevel] = useState('');
    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    },[]);

    useEffect(() => {
        loadData();
    },[page,level]);

    const loadData = () => {
        api.get('/books?page='+page+(level != ""?'&level='+encodeURIComponent(level):'')).then(function(response) { return response; })
            .then(function(resultData) {
                if (resultData.success){
                    setData(resultData.data);
                }
            });
    }
    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <Page title="Books | eagles.uz">
            <Container>
                <Card sx={{mt:3}}>
                    <CardHeader title={ "Books "} />
                    <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                        <FormControl key={'f1'} sx={{ m: 1, minWidth: 100 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Level</InputLabel>
                            <Select
                                id="level-select"
                                value={level}
                                label="level"
                                onChange={(event)=>{setLevel(event.target.value)}}
                                key={'s1'}
                            >
                                <MenuItem value={''}>-All-</MenuItem>
                                <MenuItem value={"A1"}>A1</MenuItem>
                                <MenuItem value={"A2"}>A2</MenuItem>
                                <MenuItem value={"B1"}>B1</MenuItem>
                                <MenuItem value={"B1+"}>B1+</MenuItem>
                                <MenuItem value={"B2"}>B2</MenuItem>
                                <MenuItem value={"B2+"}>B2+</MenuItem>
                                <MenuItem value={"C1"}>C1</MenuItem>
                                <MenuItem value={"C2"}>C2</MenuItem>
                            </Select>
                        </FormControl>
                        {data?.data?.map((row) => (
                            <Link to={'/dashboard/book/'+row.book_id} color="inherit" underline="none"  component={RouterLink}>
                                <Card sx={{ display: 'flex',my:1 }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 151 }}
                                        image={"https://files.eagles.uz/book-covers/" + row.book_id+".jpg"}
                                        alt="Live from space album cover"
                                    />
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Badge color="primary" badgeContent={row.book_level}>
                                                <Typography component="div" variant="h5">
                                                    {row.book_name}
                                                </Typography>
                                            </Badge>
                                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                                {row.book_author}
                                            </Typography>
                                            <Typography variant="p" color="text.secondary" component="div">
                                                {row.book_description}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Link>
                        ))}
                        <Pagination count={data.last_page} page={page} onChange={handleChangePage} color="primary" variant="outlined" shape="rounded" />
                    </Box>
                </Card>


            </Container>
        </Page>
    );
}