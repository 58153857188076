import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, Button, Divider, Typography } from '@mui/material';
// import { GoogleLogin } from 'react-google-login';
import { signInWithGoogle } from 'src/firebase/google';
import api from '../../api';
import axios from 'axios';
import config from '../../config.json';

import TelegramLoginButton from 'react-telegram-login';

// ----------------------------------------------------------------------

export default function AuthSocial() {
  const navigate = useNavigate();

  const onGoogleLogin = async () => {
    const result = await signInWithGoogle();
    if (!result) {
      return alert('Login failed');
    }
    console.log(result);
    let loginData = {
      google_id: result._tokenResponse.idToken,
      fullName: result.user.displayName,
      email: result.user.email,
    };

    axios.post(config.apiUrl + '/login', loginData, {
      headers: {
        "X-App-Token": config.appToken
      }
    }).then((response) => {
      console.log(response.data.data.user_token);
      if (response.data.success) {
        api.setUser(response.data.data);
        navigate('/dashboard/home');
      }
    }).catch((error) => {
      console.log(error);
      if (error.response != undefined) {
        alert(error.response.data.message);
      }
    })

    // MOCK
    // let mockUser = { user_id: 2, user_full_name: "Test account", user_phone_number: "123456789", user_token: "8nnL8uFq19zjl6gH1BEqVMzBHVKGE5ndZJTkthwU1646937941" }
    // api.setUser(mockUser);
    // navigate('/dashboard/home');
  };

  const onTelegramLogin = (data) => {
    console.log(data);
    let loginData = {
      telegram_id: data.id,
      fullName: data.first_name + ' ' + data.last_name
    }
    console.log(loginData);

    axios.post(config.apiUrl + '/login', loginData, {
      headers: {
        "X-App-Token": config.appToken
      }
    }).then((response) => {
      console.log(response.data.data.user_token);
      if (response.data.success) {
        api.setUser(response.data.data);
        navigate('/dashboard/home');
      }
    }).catch((error) => {
      console.log(error);
      if (error.response != undefined) {
        alert(error.response.data.message);
      }
    })

    // MOCK
    // let mockUser = { user_id: 2, user_full_name: "Test account", user_phone_number: "123456789", user_token: "8nnL8uFq19zjl6gH1BEqVMzBHVKGE5ndZJTkthwU1646937941" }
    // api.setUser(mockUser);
    // navigate('/dashboard/home');;
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onGoogleLogin}>
          <Icon icon={googleFill} color="#DF3E30" height={24} />
        </Button>

        <TelegramLoginButton
          dataOnauth={onTelegramLogin}
          botName={config.telegram.bot_username}
        />

        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon="akar-icons:telegram-fill" color="#229ed9" />
        </Button> */}
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}
