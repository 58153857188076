import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import Page from '../components/Page';
import WordlistItem from '../components/custom/WordlistItem';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import {useParams} from "react-router-dom";
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import refreshIcon from '@iconify/icons-ant-design/sync';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";


export default function Wordlist() {
  const [folders, setState] = useState([]);
  const [refresh, setRefresh] = useState(1);
  let { folderId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    axios.get(config.apiUrl+'/folder/wordlist?folder_id='+folderId, {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response)=>{
      if (response.data.success){
        setState(response.data.data);
        api.getUser();
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
  },[refresh,folderId]);

  const refreshWordlist = () => {
    axios.get(config.apiUrl+'/refresh-repeat-words', {
      headers: {
        'Authorization': api.getToken()
      }
    }).then((response)=>{
      if (response.data.success){
        setRefresh(refresh*(-1));
      }
    }).catch((error)=>{
      if (error.response != undefined){
        if (error.response.status == 401){
          api.logOut();
        }else{
          alert(error.response.data.message);
        }
      }
    });
  }
  return (
      <Page title="Wordlist | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <Grid container spacing={3}>
            <Grid item xs={8} padding={1}>
              <Box sx={{ pb: 5 }}>
                <Typography variant="h4">Wordlists { folderId<0?"for repeat":"" }</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} padding={1}>

              { folderId==="forRepeat"?
                  <IconButton aria-label="fingerprint" color="success"  onClick={() => refreshWordlist()}>
                    <Icon icon={refreshIcon} width={24} height={24}/>
                  </IconButton>
                  :"" }
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {folders.map(function(item, i){
              return <Grid item xs={12} md={6} lg={4} padding={1} key={i}><WordlistItem item={item} /></Grid>
            })}
          </Grid>
        </Container>
      </Page>
  );
}