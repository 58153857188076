import {Box, Button, Container, Grid, IconButton, Typography} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import config from '../config.json';
import api from '../api';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import backIcon from '@iconify/icons-ant-design/arrow-left-outline';
import audioIcon  from '@iconify/icons-ant-design/audio';
import audiofilledIcon  from '@iconify/icons-ant-design/audio-filled';
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

export default function Speech() {
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [currentWordTranslate, setCurrentWordTranslate] = useState('');
  const [index, setIndex] = useState(0);
  const [error, setError] = useState(false);
  const [complate, setComplete] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  let { wordlist_id } = useParams();
  let navigate = useNavigate();

  const {
    transcript,
    listening,
    resetTranscript,
    finalTranscript,
    interimTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    axios.get(config.apiUrl + '/wordlist/words', {
      headers: {
        'Authorization': api.getToken()
      },
      params: {
        wordlist_id: wordlist_id
      }
    }).then((response) => {
      if (response.data.success) {
        setWords(response.data.data);
        setCurrentWord(response.data.data[index]['word2']);
        setCurrentWordTranslate(response.data.data[index]['word1']);
        setTotalCount(response.data.data.length);
        setPendingCount(response.data.data.length);

      }
    }).catch((error) => {
      if (error.response != undefined) {
        if (error.response.status == 401) {
          api.logOut();
        } else {
          alert(error.response.data.message);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (finalTranscript !== '') {
      handleCheck(finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);

  const handleCheck = (word) => {

    let point = 0;
    if ( word.trim().toLowerCase() !== words[index]['word1'].trim().toLowerCase()) {
      point = -1;
      setError(true);
      if (!error) {
        setErrorCount((errorCount + 1));
      }
    } else {
      point = 1;
      setSuccessCount((successCount + 1));
      setError(false);
      if ((index + 2) > words.length) {
        setComplete(true);
      } else {
        setIndex((index + 1));
        setCurrentWord(words[index + 1]['word2']);
        setCurrentWordTranslate(words[index + 1]['word1']);
        resetTranscript();
      }
    }
    if (point !== 0) {
      axios.post(config.apiUrl + '/word/action/speech',
          {
            word_id: words[index]['word_id'],
            point: point
          },
          {
            headers: {
              'Authorization': api.getToken()
            }
          }
      ).then((response) => {
        if (response.data.success) {
          //window.location.href='/dashboard/folder/'+wordlist_id;
        }
      }).catch((error) => {
        if (error.response != undefined) {
          if (error.response.status == 401) {
            api.logOut();
          } else {
            // alert(error.response.data.message);
          }
        }
      });

    }
  }
  const  play_sound = ()=>{
    let error = 0;
    let item = words[index];
    if(item) {
      if (!item.has_audio) {
        axios.get(config.apiUrl + '/word/get-audio?word_id=' + item.id, {
          headers: {
            'Authorization': api.getToken()
          },
        }).then((response) => {
          if (response.data.success) {
            let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
            player.play();
          }
        }).catch((error) => {
          error = 1;
          if (error.response != undefined) {
            if (error.response.status == 401) {
              api.logOut();
            } else {
              alert(error.response.data.message);
            }
          }
        });
      } else {
        if (error == 0) {
          let player = new Audio(config.filesUrl + "/audio/" + item.folder_id + "/" + item.original_wordlist_id + "/" + item.word_id + '.mp3');
          player.play();
        }
      }
    }
  }
  const handleListen = () => {
    SpeechRecognition.startListening({ language: 'en-GB' });
  }
  const handleSkip = () => {
    setIndex((index + 1));
    setCurrentWord(words[index + 1]['word2']);
    setCurrentWordTranslate(words[index + 1]['word1']);
    resetTranscript();
  }
  return (
      <Page title="Folders | eagles.uz">
        <Container>
          <IconButton aria-label="fingerprint" color="success"  onClick={() => navigate(-1)}>
            <Icon icon={backIcon} width={24} height={24}/>
          </IconButton>
          <br /><br />

          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} >
              {
                complate === false ?
                    <>
                      <Box sx={{ py: 2 }}>
                        <Typography>Definition:</Typography>
                        <Typography variant="h4">
                          {currentWord} </Typography>
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <Typography>Audio:</Typography>
                        <Button
                            size="large"
                            style={{ width:40,height:40 }}
                            onClick={ () => play_sound() }
                            variant="contained"
                        >
                          <Icon icon={audioIcon} width={24} height={24}/>
                        </Button>
                      </Box>
                      <Box sx={{ py: 3 }}>
                        <Typography>You answer:</Typography>
                        <Typography variant="h4">
                          {transcript}
                        </Typography>
                      </Box>
                      <Box  sx={{ py: 3 }}>
                        <Button size="large" variant="contained" onClick={handleListen}>
                          {listening ? <> <Icon icon={audiofilledIcon} width={24} height={24}/> Listening ... </> : <>  <Icon icon={audioIcon} width={24} height={24}/> Start speech </>}
                        </Button>

                        <Button size="large" variant="contained" onClick={handleSkip} sx={{ mx:1 }} style={{float: 'right'}}>
                          Skip this word
                        </Button>
                      </Box>
                    </>
                    :
                    <>
                      <center>
                        <Typography variant="h4">Speech complated <br /> Total: {successCount} Errors: {errorCount}</Typography>
                      </center>
                    </>
              }
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}