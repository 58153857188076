import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', my: 1 }}>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center">
                    <Typography variant="h5" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`} &nbsp; {`${props.current}/${props.total}`}</Typography>
                </Box>
                <LinearProgress variant="determinate" {...props} style={{ height: "15px", borderRadius: "5px" }}/>
            </Box>

        </Box>
    );
}

export default function ProgressBar({current = 0, total=1}) {

    let percentage = current/total*100;
    if (isNaN(percentage)){
        percentage = 0;
    }
    return(
        <LinearProgressWithLabel value={percentage} current={current} total={total}/>
    );
}